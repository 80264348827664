export const GET_DEPOSIT_SETTING_STARTED = "GET_DEPOSIT_SETTING_STARTED"
export const GET_DEPOSIT_SETTING_SUCCESS = "GET_DEPOSIT_SETTING_SUCCESS"
export const GET_DEPOSIT_SETTING_FAILURE = "GET_DEPOSIT_SETTING_FAILURE"

export const GET_DEPOSIT_CLIENTS_STARTED = "GET_DEPOSIT_CLIENTS_STARTED"
export const GET_DEPOSIT_CLIENTS_SUCCESS = "GET_DEPOSIT_CLIENTS_SUCCESS"
export const GET_DEPOSIT_CLIENTS_FAILURE = "GET_DEPOSIT_CLIENTS_FAILURE"

export const GET_DEPOSIT_SERVICES_STARTED = "GET_DEPOSIT_SERVICES_STARTED"
export const GET_DEPOSIT_SERVICES_SUCCESS = "GET_DEPOSIT_SERVICES_SUCCESS"
export const GET_DEPOSIT_SERVICES_FAILURE = "GET_DEPOSIT_SERVICES_FAILURE"

export const UPDATE_DEPOSIT_SETTING_STARTED = "UPDATE_DEPOSIT_SETTING_STARTED"
export const UPDATE_DEPOSIT_SETTING_SUCCESS = "UPDATE_DEPOSIT_SETTING_SUCCESS"
export const UPDATE_DEPOSIT_SETTING_FAILURE = "UPDATE_DEPOSIT_SETTING_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
