import {
    CREATE_APPOINTMENT_STARTED,
    CREATE_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_FAILURE,
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENT_DETAIL_STARTED,
    GET_APPOINTMENT_DETAIL_SUCCESS,
    GET_APPOINTMENT_DETAIL_FAILURE,
    CANCEL_APPOINTMENT_STARTED,
    CANCEL_APPOINTMENT_SUCCESS,
    CANCEL_APPOINTMENT_FAILURE,
    RESCHEDULE_APPOINTMENT_STARTED,
    RESCHEDULE_APPOINTMENT_SUCCESS,
    RESCHEDULE_APPOINTMENT_FAILURE,
    UPDATE_APPOINTMENT_SERVICES_STARTED,
    UPDATE_APPOINTMENT_SERVICES_SUCCESS,
    UPDATE_APPOINTMENT_SERVICES_FAILURE,
    UPDATE_STATUS_STARTED,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAILURE,
    CONFIRM_APPOINTMENT_STARTED,
    CONFIRM_APPOINTMENT_SUCCESS,
    CONFIRM_APPOINTMENT_FAILURE,
    CHECKED_IN_STARTED,
    CHECKED_IN_SUCCESS,
    CHECKED_IN_FAILURE,
    UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_STARTED,
    UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_SUCCESS,
    UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_FAILURE,
    SEND_CLIENT_TEXT_REMINDER_STARTED,
    SEND_CLIENT_TEXT_REMINDER_SUCCESS,
    SEND_CLIENT_TEXT_REMINDER_FAILURE,
    CREATE_BLOCK_TIME_STARTED,
    CREATE_BLOCK_TIME_SUCCESS,
    CREATE_BLOCK_TIME_FAILURE,
    UPDATE_BLOCK_TIME_STARTED,
    UPDATE_BLOCK_TIME_SUCCESS,
    UPDATE_BLOCK_TIME_FAILURE,
    REMOVE_BLOCK_TIME_STARTED,
    REMOVE_BLOCK_TIME_SUCCESS,
    REMOVE_BLOCK_TIME_FAILURE,
    GET_BLOCK_TIMES_STARTED,
    GET_BLOCK_TIMES_SUCCESS,
    GET_BLOCK_TIMES_FAILURE,
    REPLY_REVIEW_STARTED,
    REPLY_REVIEW_SUCCESS,
    REPLY_REVIEW_FAILURE,
    SEND_REVIEW_SMS_STARTED,
    SEND_REVIEW_SMS_SUCCESS,
    SEND_REVIEW_SMS_FAILURE,
    DUPLICATE_SERVICE_STARTED,
    DUPLICATE_SERVICE_SUCCESS,
    DUPLICATE_SERVICE_FAILURE,
    GET_APPOINTMENTS_FOR_DUPLICATE_STARTED,
    GET_APPOINTMENTS_FOR_DUPLICATE_SUCCESS,
    GET_APPOINTMENTS_FOR_DUPLICATE_FAILURE,
    SEND_DEPOSIT_LINK_STARTED,
    SEND_DEPOSIT_LINK_SUCCESS,
    SEND_DEPOSIT_LINK_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/appointment/appointment";

const initialState = {
    appointments: [],
    appointment: null,
    appointmentsForDuplicate: [],
    review: null,
    client: null,
    blockTimes: [],
    loading: false,
    loadingUpdate: false,
    loadingDuplicate: false,
    error: null,
    message: null,
    component: ""
};

export default function appointment(state = initialState, action) {
    let updatedAppointment = null
    switch (action.type) {
        case SEND_DEPOSIT_LINK_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case SEND_DEPOSIT_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Deposit link has been sent successfully!"
            };
        case SEND_DEPOSIT_LINK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_APPOINTMENTS_FOR_DUPLICATE_STARTED:
            return {
                ...state,
                appointmentsForDuplicate: [],
                loadingDuplicate: true,
            };
        case GET_APPOINTMENTS_FOR_DUPLICATE_SUCCESS:
            return {
                ...state,
                loadingDuplicate: false,
                appointmentsForDuplicate: action.payload.appointments
            };
        case GET_APPOINTMENTS_FOR_DUPLICATE_FAILURE:
            return {
                ...state,
                loadingDuplicate: false,
                error: action.payload.error
            };
        case DUPLICATE_SERVICE_STARTED:
            return {
                ...state,
                error: null,
                message: null,
            }
        case DUPLICATE_SERVICE_SUCCESS:
            let newAppointmentList = state.appointments
            const duplicatedAppointments = action.payload.data
            if (duplicatedAppointments[0].date === newAppointmentList[0].date) {
                newAppointmentList = [...newAppointmentList, ...duplicatedAppointments]
            }
            return {
                ...state,
                appointments: newAppointmentList,
                message: "Service has been duplicated successfully",
            }
        case DUPLICATE_SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            }
        case SEND_REVIEW_SMS_STARTED:
            return { ...state, loading: true, error: null, message: null };
        case SEND_REVIEW_SMS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Review SMS has been sent successfully!",
                appointment: { ...state.appointment, history: action.payload.data.history, sentReviewReq: true }
            }
        case SEND_REVIEW_SMS_FAILURE:
            return { ...state, loading: false, error: action.payload.error, message: null };
        case REPLY_REVIEW_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case REPLY_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Reply has been sent successfully!",
                review: action.payload.review
            };
        case REPLY_REVIEW_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            };
        case UPDATE_APPOINTMENT_SERVICES_STARTED:
            return {
                ...state,
                error: null,
                message: null
            }
        case UPDATE_APPOINTMENT_SERVICES_SUCCESS:
            return {
                ...state,
                message: "Appointment has been updated successfully"
            }
        case UPDATE_APPOINTMENT_SERVICES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case CONFIRM_APPOINTMENT_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null
            }
        case CONFIRM_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
                message: "Appointment has been confirmed!",
                appointment: { ...state.appointment, status: "Confirmed", history: action.payload.appointment.history }
            }
        case CONFIRM_APPOINTMENT_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
                error: action.payload.error
            }
        case RESCHEDULE_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "RescheduleAppointment"
            };
        case RESCHEDULE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                appointments: [...state.appointments, action.payload.data],
                appointment: action.payload.data,
                message: "Appointment has been changed successfully!"
            };
        case RESCHEDULE_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_APPOINTMENTS_STARTED:
            return {
                ...state,
                appointments: [],
                loading: true,
            };
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                appointments: action.payload.appointments
            };
        case GET_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case SEND_CLIENT_TEXT_REMINDER_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null,
                component: "ViewAppointmentPage"
            };
        case SEND_CLIENT_TEXT_REMINDER_SUCCESS:
            let appointment = { ...state.appointment };
            updatedAppointment = action.payload.appointment;
            for (const [key, value] of Object.entries(updatedAppointment)) {
                if (value) {
                    appointment[key] = value;
                }
            }
            return {
                ...state,
                loadingUpdate: false,
                appointment,
                message: "Message was sent successfully!"
            };
        case SEND_CLIENT_TEXT_REMINDER_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
                error: action.payload.error,
                message: null
            };

        case CANCEL_APPOINTMENT_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null,
                component: "CancelAppointmentModal"
            };
        case CANCEL_APPOINTMENT_SUCCESS:
            let canceledAppointment = { ...state.appointment };
            let successCanceledAppointment = action.payload.appointment;
            for (const [key, value] of Object.entries(
                successCanceledAppointment
            )) {
                if (value) {
                    canceledAppointment[key] = value;
                }
            }
            return {
                ...state,
                loadingUpdate: false,
                appointment: canceledAppointment,
                message: "Appointment updated successfully!"
            };
        case CANCEL_APPOINTMENT_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
                error: action.payload.error,
                message: null
            };
        case GET_APPOINTMENT_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                appointment: null,
                // error: null,
                // message: null,

            };
        case GET_APPOINTMENT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                appointment: action.payload.appointment,
                review: action.payload.review
            };
        case GET_APPOINTMENT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            };
        case CREATE_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "CreateAppointmentContainer"
            };
        case CREATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                appointments: [...state.appointments, action.payload.data],
                appointment: action.payload.data,
                message: "Appointment created successfully!"
            };
        case CREATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_STATUS_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null,
            }
        case UPDATE_STATUS_SUCCESS:
            let updateStatusApp = { ...state.appointment };
            let updateedStatusApp = action.payload.appointment;
            for (const [key, value] of Object.entries(updateedStatusApp)) {
                if (value) {
                    updateStatusApp[key] = value;
                }
            }
            return {
                ...state,
                loadingUpdate: false,
                appointment: updateStatusApp,
            }
        case UPDATE_STATUS_FAILURE:
            return {
                loadingUpdate: false,
                error: action.payload.error,
                message: null
            }
        case UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null,
            }
        case UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
                message: "Staff has been updated successfully!",
                appointment: action.payload.data,
            }
        case UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_FAILURE:
            return {
                loadingUpdate: false,
                error: action.payload.error,
            }
        case CHECKED_IN_STARTED:
            return {
                ...state,
                loadingUpdate: true,
                error: null,
                message: null,
            }
        case CHECKED_IN_SUCCESS: {
            updatedAppointment = action.payload.appointment
            return {
                ...state,
                appointment: updatedAppointment,
                loadingUpdate: false,
                error: null,
                message: null,
            }
        }
        case CHECKED_IN_FAILURE:
            return {
                ...state,
                loadingUpdate: false,
                error: action.payload.error,
                message: null
            }

        case CREATE_BLOCK_TIME_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CREATE_BLOCK_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Block time created successfully!"
            };
        case CREATE_BLOCK_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_BLOCK_TIME_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_BLOCK_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Block time has been changed successfully!"
            };
        case UPDATE_BLOCK_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case REMOVE_BLOCK_TIME_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case REMOVE_BLOCK_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Block time has been removed successfully!"
            };
        case REMOVE_BLOCK_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case GET_BLOCK_TIMES_STARTED:
            return {
                ...state,
                blockTimes: [],
                loading: true,
            };
        case GET_BLOCK_TIMES_SUCCESS:
            return {
                ...state,
                loading: false,
                blockTimes: action.payload.blockTimes
            };
        case GET_BLOCK_TIMES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                error: null,
                message: null
            };
        }

        default:
            return {
                ...state
            };
    }
}
