export const CREATE_BUSINESS_CLIENT_STARTED = "CREATE_BUSINESS_CLIENT_STARTED"
export const CREATE_BUSINESS_CLIENT_SUCCESS = "CREATE_BUSINESS_CLIENT_SUCCESS"
export const CREATE_BUSINESS_CLIENT_FAILURE = "CREATE_BUSINESS_CLIENT_FAILURE"


export const SEARCH_BUSINESS_CLIENT_STARTED = "SEARCH_BUSINESS_CLIENT_STARTED"
export const SEARCH_BUSINESS_CLIENT_SUCCESS = "SEARCH_BUSINESS_CLIENT_SUCCESS"
export const SEARCH_BUSINESS_CLIENT_FAILURE = "SEARCH_BUSINESS_CLIENT_FAILURE"

export const CLEAR_SEARCH_RESULT = "CLEAR_SEARCH_RESULT"

export const UPDATE_BUSINESS_CLIENT_STARTED = "UPDATE_BUSINESS_CLIENT_STARTED"
export const UPDATE_BUSINESS_CLIENT_SUCCESS = "UPDATE_BUSINESS_CLIENT_SUCCESS"
export const UPDATE_BUSINESS_CLIENT_FAILURE = "UPDATE_BUSINESS_CLIENT_FAILURE"

export const GET_BUSINESS_CLIENT_STARTED = "GET_BUSINESS_CLIENT_STARTED"
export const GET_BUSINESS_CLIENT_SUCCESS = "GET_BUSINESS_CLIENT_SUCCESS"
export const GET_BUSINESS_CLIENT_FAILURE = "GET_BUSINESS_CLIENT_FAILURE"

export const GET_CLIENT_APPOINTMENT_STARTED = "GET_CLIENT_APPOINTMENT_STARTED"
export const GET_CLIENT_APPOINTMENT_SUCCESS = "GET_CLIENT_APPOINTMENT_SUCCESS"
export const GET_CLIENT_APPOINTMENT_FAILURE = "GET_CLIENT_APPOINTMENT_FAILURE"

export const GET_CLIENT_PASSED_APPOINTMENT_STARTED = "GET_CLIENT_PASSED_APPOINTMENT_STARTED"
export const GET_CLIENT_PASSED_APPOINTMENT_SUCCESS = "GET_CLIENT_PASSED_APPOINTMENT_SUCCESS"
export const GET_CLIENT_PASSED_APPOINTMENT_FAILURE = "GET_CLIENT_PASSED_APPOINTMENT_FAILURE"

// export const GET_CLIENT_LOYALTY_PROGRAM_STARTED = "GET_CLIENT_LOYALTY_PROGRAM_STARTED"
// export const GET_CLIENT_LOYALTY_PROGRAM_SUCCESS = "GET_CLIENT_LOYALTY_PROGRAM_SUCCESS"
// export const GET_CLIENT_LOYALTY_PROGRAM_FAILURE = "GET_CLIENT_LOYALTY_PROGRAM_FAILURE"

export const CREATE_CLIENT_LOYALTY_PROGRAM_STARTED = "CREATE_CLIENT_LOYALTY_PROGRAM_STARTED"
export const CREATE_CLIENT_LOYALTY_PROGRAM_SUCCESS = "CREATE_CLIENT_LOYALTY_PROGRAM_SUCCESS"
export const CREATE_CLIENT_LOYALTY_PROGRAM_FAILURE = "CREATE_CLIENT_LOYALTY_PROGRAM_FAILURE"

export const UPDATE_CLIENT_LOYALTY_PROGRAM_STARTED = "UPDATE_CLIENT_LOYALTY_PROGRAM_STARTED"
export const UPDATE_CLIENT_LOYALTY_PROGRAM_SUCCESS = "UPDATE_CLIENT_LOYALTY_PROGRAM_SUCCESS"
export const UPDATE_CLIENT_LOYALTY_PROGRAM_FAILURE = "UPDATE_CLIENT_LOYALTY_PROGRAM_FAILURE"

export const APPLY_CLIENT_LOYALTY_PROGRAM_STARTED = "APPLY_CLIENT_LOYALTY_PROGRAM_STARTED"
export const CANCEL_CLIENT_LOYALTY_PROGRAM_STARTED = "CANCEL_CLIENT_LOYALTY_PROGRAM_STARTED"

export const DELETE_CLIENT_LOYALTY_PROGRAM_STARTED = "DELETE_CLIENT_LOYALTY_PROGRAM_STARTED"
export const DELETE_CLIENT_LOYALTY_PROGRAM_SUCCESS = "DELETE_CLIENT_LOYALTY_PROGRAM_SUCCESS"
export const DELETE_CLIENT_LOYALTY_PROGRAM_FAILURE = "DELETE_CLIENT_LOYALTY_PROGRAM_FAILURE"

export const BLOCK_BUSINESS_CLIENT_STARTED = "BLOCK_BUSINESS_CLIENT_STARTED"
export const BLOCK_BUSINESS_CLIENT_SUCCESS = "BLOCK_BUSINESS_CLIENT_SUCCESS"
export const BLOCK_BUSINESS_CLIENT_FAILURE = "BLOCK_BUSINESS_CLIENT_FAILURE"

export const DELETE_BUSINESS_CLIENT_STARTED = "DELETE_BUSINESS_CLIENT_STARTED"
export const DELETE_BUSINESS_CLIENT_SUCCESS = "DELETE_BUSINESS_CLIENT_SUCCESS"
export const DELETE_BUSINESS_CLIENT_FAILURE = "DELETE_BUSINESS_CLIENT_FAILURE"

export const TOGGLE_CLIENT_DEPOSIT_REQUIRED_STARTED = "TOGGLE_CLIENT_DEPOSIT_REQUIRED_STARTED"
export const TOGGLE_CLIENT_DEPOSIT_REQUIRED_SUCCESS = "TOGGLE_CLIENT_DEPOSIT_REQUIRED_SUCCESS"
export const TOGGLE_CLIENT_DEPOSIT_REQUIRED_FAILURE = "TOGGLE_CLIENT_DEPOSIT_REQUIRED_FAILURE"

export const REMOVE_BUSINESS_CLIENT = "REMOVE_BUSINESS_CLIENT"

export const CLEAR_BUSINESS_CLIENT = "CLEAR_BUSINESS_CLIENT"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"

