import {
    CREATE_BUSINESS_CLIENT_STARTED,
    CREATE_BUSINESS_CLIENT_SUCCESS,
    CREATE_BUSINESS_CLIENT_FAILURE,
    SEARCH_BUSINESS_CLIENT_STARTED,
    SEARCH_BUSINESS_CLIENT_SUCCESS,
    SEARCH_BUSINESS_CLIENT_FAILURE,
    CLEAR_SEARCH_RESULT,
    UPDATE_BUSINESS_CLIENT_STARTED,
    UPDATE_BUSINESS_CLIENT_SUCCESS,
    UPDATE_BUSINESS_CLIENT_FAILURE,
    GET_BUSINESS_CLIENT_STARTED,
    GET_BUSINESS_CLIENT_SUCCESS,
    GET_BUSINESS_CLIENT_FAILURE,
    DELETE_BUSINESS_CLIENT_STARTED,
    DELETE_BUSINESS_CLIENT_SUCCESS,
    DELETE_BUSINESS_CLIENT_FAILURE,
    BLOCK_BUSINESS_CLIENT_STARTED,
    BLOCK_BUSINESS_CLIENT_SUCCESS,
    BLOCK_BUSINESS_CLIENT_FAILURE,
    REMOVE_BUSINESS_CLIENT,
    REMOVE_ERROR_MESSAGE,
    GET_CLIENT_APPOINTMENT_STARTED,
    GET_CLIENT_APPOINTMENT_SUCCESS,
    GET_CLIENT_APPOINTMENT_FAILURE,

    GET_CLIENT_PASSED_APPOINTMENT_STARTED,
    GET_CLIENT_PASSED_APPOINTMENT_SUCCESS,
    GET_CLIENT_PASSED_APPOINTMENT_FAILURE,
    CLEAR_BUSINESS_CLIENT,

    // GET_CLIENT_LOYALTY_PROGRAM_STARTED,
    // GET_CLIENT_LOYALTY_PROGRAM_SUCCESS,
    // GET_CLIENT_LOYALTY_PROGRAM_FAILURE,

    CREATE_CLIENT_LOYALTY_PROGRAM_STARTED,
    CREATE_CLIENT_LOYALTY_PROGRAM_SUCCESS,
    CREATE_CLIENT_LOYALTY_PROGRAM_FAILURE,

    UPDATE_CLIENT_LOYALTY_PROGRAM_STARTED,
    UPDATE_CLIENT_LOYALTY_PROGRAM_SUCCESS,
    UPDATE_CLIENT_LOYALTY_PROGRAM_FAILURE,

    APPLY_CLIENT_LOYALTY_PROGRAM_STARTED,
    CANCEL_CLIENT_LOYALTY_PROGRAM_STARTED,

    DELETE_CLIENT_LOYALTY_PROGRAM_STARTED,
    DELETE_CLIENT_LOYALTY_PROGRAM_SUCCESS,
    DELETE_CLIENT_LOYALTY_PROGRAM_FAILURE,

    TOGGLE_CLIENT_DEPOSIT_REQUIRED_STARTED,
    TOGGLE_CLIENT_DEPOSIT_REQUIRED_SUCCESS,
    TOGGLE_CLIENT_DEPOSIT_REQUIRED_FAILURE,

} from "../../constants/business-client/businessClient";

const initialState = {
    clients: [],
    countClients: 0,
    client: null,
    appointments: [],
    passedAppointments: [],
    passedAppointmentsCount: 0,
    count: 0,
    loading: false,
    loadingAppointments: false,

    //new
    //loyaltyProgram: null,
    previouslyMinusTopUpCap: false,
    appliedLoyaltyOneTime: false,
    loadingLoyaltyProgram: false,

    error: null,
    message: null,
    component: "",
};

export default function businessClient(state = initialState, action) {
    switch (action.type) {
        // //new
        // case GET_CLIENT_LOYALTY_PROGRAM_STARTED:
        //     return {
        //         ...state,
        //         loadingLoyaltyProgram: true,
        //         error: null,
        //         message: null,
        //     }
        // case GET_CLIENT_LOYALTY_PROGRAM_SUCCESS:
        //     return {
        //         ...state,
        //         loadingLoyaltyProgram: false,
        //         loyaltyProgram: action.payload.program,
        //     }
        // case GET_CLIENT_LOYALTY_PROGRAM_FAILURE:
        //     return {
        //         ...state,
        //         loadingLoyaltyProgram: false,
        //         error: action.payload.error,
        //         message: null,
        //     }

        //new
        case CREATE_CLIENT_LOYALTY_PROGRAM_STARTED:
            return {
                ...state,
                loadingLoyaltyProgram: true,
                error: null,
                message: null,
            }
        case CREATE_CLIENT_LOYALTY_PROGRAM_SUCCESS:

            return {
                ...state,
                client: { ...state.client, loyaltyProgram: action.payload.program },
                // loyaltyProgram: action.payload.program,
                loadingLoyaltyProgram: false,
                error: null,
                message: "Loyalty program has been saved successfully!",
            }
        case CREATE_CLIENT_LOYALTY_PROGRAM_FAILURE:
            return {
                ...state,
                loadingLoyaltyProgram: false,
                error: action.payload.error,
                message: null,
            }

        case APPLY_CLIENT_LOYALTY_PROGRAM_STARTED:
            let newLoyaltyProgram = state.client.loyaltyProgram
            const { cap, enableTopUp, topUpDiscounts, discountAfterTaxes } = action.payload
            let { currentPoint } = newLoyaltyProgram

            let minusTopUpCapApply = state.previouslyMinusTopUpCap

            if (!enableTopUp) {
                newLoyaltyProgram.currentPoint = newLoyaltyProgram.currentPoint - cap
            }
            else {
                let capTopUp = topUpDiscounts[topUpDiscounts.length - 1].atPoint
                if (currentPoint >= capTopUp) {
                    newLoyaltyProgram.currentPoint = newLoyaltyProgram.currentPoint - capTopUp
                    minusTopUpCapApply = true
                }
            }

            return {
                ...state,
                client: { ...state.client, loyaltyProgram: newLoyaltyProgram },
                previouslyMinusTopUpCap: minusTopUpCapApply,
                appliedLoyaltyOneTime: (discountAfterTaxes || enableTopUp) ? true : state.appliedLoyaltyOneTime,
                error: null,
                message: "Applied discount from loyalty program successfully!",
            }

        case CANCEL_CLIENT_LOYALTY_PROGRAM_STARTED:
            let canceledLoyaltyProgram = state.client.loyaltyProgram
            let capTopUp = action.payload.topUpDiscounts[action.payload.topUpDiscounts.length - 1]?.atPoint
            let minusTopUpCapCancel = state.previouslyMinusTopUpCap

            if (!action.payload.enableTopUp) {
                canceledLoyaltyProgram.currentPoint = canceledLoyaltyProgram.currentPoint + action.payload.cap
            } else {
                if (state.previouslyMinusTopUpCap) {
                    canceledLoyaltyProgram.currentPoint = canceledLoyaltyProgram.currentPoint + capTopUp
                    minusTopUpCapCancel = false
                }
            }

            return {
                ...state,
                client: { ...state.client, loyaltyProgram: canceledLoyaltyProgram },
                previouslyMinusTopUpCap: minusTopUpCapCancel,
                appliedLoyaltyOneTime: (action.payload.discountAfterTaxes || action.payload.enableTopUp) ? false : state.appliedLoyaltyOneTime,
                error: null,
                message: "Canceled loyalty discount from sale successfully!",
            }

        //new
        case UPDATE_CLIENT_LOYALTY_PROGRAM_STARTED:
            return {
                ...state,
                loadingLoyaltyProgram: true,
                error: null,
                message: null,
            }
        case UPDATE_CLIENT_LOYALTY_PROGRAM_SUCCESS:
            return {
                ...state,
                client: { ...state.client, loyaltyProgram: action.payload.program },
                // loyaltyProgram: action.payload.program,
                loadingLoyaltyProgram: false,
                error: null,
                message: "Loyalty program has been saved successfully!",
            }
        case UPDATE_CLIENT_LOYALTY_PROGRAM_FAILURE:
            return {
                ...state,
                loadingLoyaltyProgram: false,
                error: action.payload.error,
                message: null,
            }

        //new
        case DELETE_CLIENT_LOYALTY_PROGRAM_STARTED:
            return {
                ...state,
                loadingLoyaltyProgram: true,
                error: null,
                message: null,
            }
        case DELETE_CLIENT_LOYALTY_PROGRAM_SUCCESS:
            return {
                ...state,
                client: { ...state.client, loyaltyProgram: null },
                loadingLoyaltyProgram: false,
                error: null,
                message: "Loyalty program has been deleted successfully!",
            }
        case DELETE_CLIENT_LOYALTY_PROGRAM_FAILURE:
            return {
                ...state,
                loadingLoyaltyProgram: false,
                error: action.payload.error,
                message: null,
            }

        case CLEAR_BUSINESS_CLIENT:
            return {
                ...state,
                clients: [],
                client: null,
                appointments: [],
                passedAppointments: [],
                passedAppointmentsCount: 0,
                count: 0,
                loading: false,
                loadingAppointments: false,
                error: null,
                message: null
            }
        case GET_CLIENT_PASSED_APPOINTMENT_STARTED:
            return {
                ...state,
                loadingPassedAppointments: true,
                error: null,
                message: null,
            };
        case GET_CLIENT_PASSED_APPOINTMENT_SUCCESS:
            // let passedAppointments = { ...state.passedAppointments };
            // if(passedAppointments[action.payload.clientId] && action.payload.page !== 1) {
            //     passedAppointments[action.payload.clientId] = [...passedAppointments[action.payload.clientId], ...action.payload.passedAppointments]
            // } else {
            //     passedAppointments[action.payload.clientId] = action.payload.passedAppointments
            // }
            // passedAppointments = {
            //     ...passedAppointments,
            // };
            return {
                ...state,
                loadingPassedAppointments: false,
                passedAppointments: [...state.passedAppointments, ...action.payload.passedAppointments],
                passedAppointmentsCount: action.payload.count
            };
        case GET_CLIENT_PASSED_APPOINTMENT_FAILURE:
            return {
                ...state,
                loadingPassedAppointments: false,
                error: action.payload.error,
                message: null,
            };
        case GET_CLIENT_APPOINTMENT_STARTED:
            return {
                ...state,
                loadingAppointments: true,
                error: null,
                message: null,
            };
        case GET_CLIENT_APPOINTMENT_SUCCESS:
            // let appointments = { ...state.appointments };
            // if(appointments[action.payload.clientId] && action.payload.page !== 1) {
            //     appointments[action.payload.clientId] = [...appointments[action.payload.clientId], ...action.payload.appointments]
            // } else {
            //     appointments[action.payload.clientId] = action.payload.appointments
            // }
            // appointments = {
            //     ...appointments,
            // };
            return {
                ...state,
                loadingAppointments: false,
                appointments: [...state.appointments, ...action.payload.appointments],
                count: action.payload.count
            };
        case GET_CLIENT_APPOINTMENT_FAILURE:
            return {
                ...state,
                loadingAppointments: false,
                error: action.payload.error,
                message: null,
            };
        case REMOVE_BUSINESS_CLIENT:
            return {
                ...state,
                client: null,
                appointments: [],
                passedAppointments: [],
            };
        case CREATE_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ClientForm",
            };
        case CREATE_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload.client,
                loading: false,
                error: null,
                message: "Saved client successfully!",
                component: "ClientForm",
            };
        case CREATE_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ClientForm",
            };
        case SEARCH_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ClientSearchBox",
            };
        case SEARCH_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                clients: action.payload.clients,
                countClients: action.payload.count,
            };
        case SEARCH_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case CLEAR_SEARCH_RESULT:
            return {
                ...state,
                appointments: [],
                passedAppointments: [],
                loading: false,
                error: null,
                message: null,
                clients: [],
            };
        case UPDATE_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "ClientForm",
            };
        case UPDATE_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload.client,
                loading: false,
                error: null,
                message: "Saved client successfully!",
            };
        case UPDATE_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                appointments: [],
                passedAppointments: [],
                loading: true,
                error: null,
                message: null,
                component: "ClientDetailContainer",
            };
        case GET_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload.client,
                loading: false,
                error: null,
                message: null,
            };
        case GET_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                appointments: [],
                passedAppointments: [],
                error: action.payload.error,
                message: null,
            };
        case DELETE_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "RemoveClientModal",
            };
        case DELETE_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                clients: state.clients.filter(
                    (client) => client._id !== action.payload.client._id
                ),
                client: { ...state.client, deleted: true },
                loading: false,
                error: null,
                message: "Client was removed successfully!",
            };
        case DELETE_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case BLOCK_BUSINESS_CLIENT_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "BlockClientModal",
            };
        case BLOCK_BUSINESS_CLIENT_SUCCESS:
            return {
                ...state,
                client: { ...state.client, block: action.payload.client.block },
                loading: false,
                message: "Client was saved successfully!",
                error: null,
            };
        case BLOCK_BUSINESS_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };
        case TOGGLE_CLIENT_DEPOSIT_REQUIRED_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case TOGGLE_CLIENT_DEPOSIT_REQUIRED_SUCCESS:
            return {
                ...state,
                client: { ...state.client, depositRate: action.payload.depositRate },
                loading: false,
                error: null,
                message: "Client was saved successfully!",
            };
        case TOGGLE_CLIENT_DEPOSIT_REQUIRED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
