import {
    GET_DEPOSIT_SETTING_STARTED,
    GET_DEPOSIT_SETTING_SUCCESS,
    GET_DEPOSIT_SETTING_FAILURE,
    GET_DEPOSIT_CLIENTS_STARTED,
    GET_DEPOSIT_CLIENTS_SUCCESS,
    GET_DEPOSIT_CLIENTS_FAILURE,
    GET_DEPOSIT_SERVICES_STARTED,
    GET_DEPOSIT_SERVICES_SUCCESS,
    GET_DEPOSIT_SERVICES_FAILURE,
    UPDATE_DEPOSIT_SETTING_STARTED,
    UPDATE_DEPOSIT_SETTING_SUCCESS,
    UPDATE_DEPOSIT_SETTING_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/depositSettings/depositSettings";

const initialState = {
    settings: null,
    isRequiredDeposit: false,
    depositClients: [],
    depositServices: [],
    countClients: 0,
    countServices: 0,
    loading: false,
    loadingClients: false,
    loadingServices: false,
    error: null,
    message: null
};

export default function depositSettings(state = initialState, action) {
    switch (action.type) {
        case GET_DEPOSIT_CLIENTS_STARTED:
            return {
                ...state,
                loadingClients: true,
            };
        case GET_DEPOSIT_CLIENTS_SUCCESS:
            return {
                ...state,
                loadingClients: false,
                depositClients: action.payload.depositClients,
                countClients: action.payload.countClients
            };
        case GET_DEPOSIT_CLIENTS_FAILURE:
            return {
                ...state,
                loadingClients: false,
                error: action.payload.error
            };
        case GET_DEPOSIT_SERVICES_STARTED:
            return {
                ...state,
                loadingServices: true,
            };
        case GET_DEPOSIT_SERVICES_SUCCESS:
            return {
                ...state,
                loadingServices: false,
                depositServices: action.payload.depositServices,
                countServices: action.payload.countServices
            };
        case GET_DEPOSIT_SERVICES_FAILURE:
            return {
                ...state,
                loadingServices: false,
                error: action.payload.error
            };
        case GET_DEPOSIT_SETTING_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_DEPOSIT_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.settings.depositSettings,
                isRequiredDeposit: action.payload.settings.isRequiredDeposit
            };
        case GET_DEPOSIT_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_DEPOSIT_SETTING_STARTED:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DEPOSIT_SETTING_SUCCESS:

            // let businessData = JSON.parse(localStorage.getItem('business'))
            // businessData = { ...businessData, isRequiredDeposit: action.payload.settings.isRequiredDeposit }
            // localStorage.setItem('business', JSON.stringify(businessData))

            return {
                ...state,
                settings: action.payload.settings.depositSettings,
                isRequiredDeposit: action.payload.settings.isRequiredDeposit,
                loading: false,
                message: "Deposit settings updated successfully"
            };
        case UPDATE_DEPOSIT_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        //remove error
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}
