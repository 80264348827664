import { combineReducers } from "redux"
import responsive from "./responsive/responsive"
import authenticate from "./salon-account/authenticate"
import businessClient from "./business-client/businessClient"
import menu from "./menu/menu"
import staff from "./staff/staff"
import businessHour from "./business-hour/business-hour"
import tax from "./tax/tax"
import appointment from "./appointment/appointment"
import availability from "./appointment/availability/availability"
import billingReport from "./report/billingReport"
import sale from "./sale/sale"
import history from "./history/history"
import notification from "./notification/notification"
import businessInfo from "./salon-account/businessInfo"
import clover from "./clover/cloverStatus"
import giftCard from "./gift-card/giftCard"
import invoice from "./invoice/invoice"
import billing from "./billing/billing"
import saleReport from './report/saleReport'
import promotion from './promotion/promotion'
import holiday from "./holiday/holiday"
import upcommingAppointments from "./upcomming-appointments/upcommingAppointments"
import timeClock from "./time-clock/time-clock";
import loyaltySetting from "./loyaltySetting/loyaltySetting"
import businessClientSettings from "./business-client-settings/businessClientSettings"
import giftCardHistory from "./gift-card-history/giftCardHistory"
import smsSettings from "./sms-settings/smsSettings"
import checkInSettings from "./check-in-settings/checkInSettings"
import giftCardSettings from "./gift-card-settings/giftCardSettings"
import staffAccount from "./staff/staffAccount"
import googleProfile from "./googleProfile/googleProfile"
import depositSettings from "./depositSettings/depositSettings"
const appReducer = combineReducers({
    responsive,
    authenticate,
    businessClient,
    menu,
    sale,
    staff,
    businessHour,
    tax,
    appointment,
    availability,
    history,
    notification,
    billingReport,
    businessInfo,
    invoice,
    clover,
    giftCard,
    billing,
    saleReport,
    promotion,
    holiday,
    upcommingAppointments,
    timeClock,
    loyaltySetting,
    businessClientSettings,
    giftCardHistory,
    smsSettings,
    checkInSettings,
    giftCardSettings,
    staffAccount,
    googleProfile,
    depositSettings
})


const initialState = appReducer({}, {})


const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT' || action.type === "LOGOUT_ALL_USER") {
        return appReducer(initialState, action)
    }
    return appReducer(state, action)
}


export default rootReducer